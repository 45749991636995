import { Pipe, PipeTransform } from '@angular/core';
import { TourOptions } from '../types/leasing-fallbacks';

@Pipe({
  name: 'tourOptionsFilter',
})
export class TourOptionsFilterPipe implements PipeTransform {
  transform(tourOptions: TourOptions): string[] {
    if (!tourOptions) {
      return [];
    }

    return Object.entries(tourOptions)
      .filter(([_, option]) => option.allowed)
      .map(([key, option]) => `${key}${option.priority !== undefined ? ` - Priority ${option.priority}` : ''}`);
  }
}
